import styled from '@emotion/styled';
import { breakpoint } from '@styles/breakpoints';
import { fontSize } from '@styles/globalStyles';

export const Wrapper = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;

  @media only screen and ${breakpoint.lg} {
    flex-wrap: wrap;
    gap: 16px;
  }

  @media only screen and ${breakpoint.md} {
    display: block;
    box-shadow: ${({ theme }) => theme.shadow.medium};
  }

  .simple-dropdown__container {
    width: 500px;
  }
`;

export const Accessories = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 0 24px 0;
  width: 100%;
`;

export const OffersCounter = styled.p`
  font-size: ${fontSize(18)};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: -0.2704px;
  color: #002330;
`;

export const ClearButton = styled.button`
  border: none;
  background-color: inherit;
  color: #002330;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const ClearButtonIcon = styled.div`
  margin-right: 10px;
`;
