import React, { memo, useCallback } from 'react';
import { useTranslation } from 'next-i18next';
import Image from 'next/image';
import Link from 'next/link';

import { prepareLink } from '@utils/dataHelpers';
import {
  Offer,
  OfferBoxHeader,
  Col,
  EmployerLogo,
  CompanyName,
  ExpirationDate,
  PreloaderContainer,
  OfferName,
  OfferSalary,
  EditButton,
} from '@components/OfferBox/styles';
import { OfferTypeLabel } from '@styles/globalStyles';
import Preloader, { POSITIONS } from '@components/Preloader';
import PencilIcon from '@assets/images/ic-edit.svg';

interface OfferBoxProps {
  offer: OfferBox;
  isFetching?: boolean;
  editable?: boolean;
  onEditClick?: (slug: string) => void;
}

function OfferBox({ offer, isFetching, editable, onEditClick }: OfferBoxProps) {
  const { t } = useTranslation('offers');

  const editButtonClick = useCallback(
    (ev) => {
      ev.stopPropagation();
      ev.preventDefault();
      if (typeof onEditClick === 'function') {
        onEditClick(offer.slug);
      }
    },
    [offer.slug, onEditClick]
  );

  return (
    <Link href={prepareLink('offer', offer.slug, offer.id)} passHref>
      <Offer className="offer-box" href={prepareLink('offer', offer.slug, offer.id)}>
        {isFetching ? (
          <PreloaderContainer className="siema">
            <Preloader position={POSITIONS.ABSOLUTE} />
          </PreloaderContainer>
        ) : (
          <>
            <OfferBoxHeader>
              <Col>
                <OfferTypeLabel type={offer.practiceType}>{t(offer.practiceType)}</OfferTypeLabel>
                <CompanyName>{offer.employerProfile.name}</CompanyName>
              </Col>
              <Col alignRight>
                <EmployerLogo>
                  {offer.employerProfile.logo.desktop ? (
                    <Image
                      src={offer.employerProfile.logo.desktop}
                      alt={offer.employerProfile.name}
                      width="100%"
                      height={60}
                      objectFit="contain"
                    />
                  ) : null}
                </EmployerLogo>
              </Col>
            </OfferBoxHeader>

            <OfferName>{offer.name}</OfferName>
            {offer.salary ? (
              <OfferSalary>
                {parseFloat(offer.salary).toLocaleString('en')}
                {offer.salaryMax ? ` - ${parseFloat(offer.salaryMax).toLocaleString('en')}` : ''}{' '}
                {offer.currency || 'PLN'}
              </OfferSalary>
            ) : null}
            {editable ? (
              <EditButton type="button" onClick={editButtonClick}>
                <Image src={PencilIcon} alt="" />
              </EditButton>
            ) : null}

            {offer.stopRecruitment ? (
              <ExpirationDate>
                {t('Expires')} {offer.stopRecruitment}
              </ExpirationDate>
            ) : null}
          </>
        )}
      </Offer>
    </Link>
  );
}

OfferBox.defaultProps = {
  isFetching: false,
  editable: false,
  onEditClick: null,
};

export default memo(OfferBox);
