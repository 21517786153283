export enum PRACTICE_TYPE {
  '' = 'All',
  permanent = 'Permanent',
  b2b = 'B2B Contract',
  contract = 'Contract',
  mandate = 'Mandate',
  other = 'Other',
}

export enum WORK_MODEL {
  '' = 'All',
  office = 'Office',
  remote = 'Remote',
  hybrid = 'Hybrid',
  mobile = 'Mobile',
}

export enum IS_PAID {
  '' = 'All',
  true = 'Yes',
  false = 'No',
}

export enum POSITION {
  '' = 'All',
  apprentice = 'Apprentice',
  junior = 'Junior',
  regular = 'Mid',
  senior = 'Senior',
  lead = 'Lead',
  manager = 'Manager',
  director = 'Director',
  board_member = 'Board Member',
}
